<template>
  <div class="zupu-translation-main-page">
    <xmas-banner v-if="$store.getters.featureSwitchesState.XMAS_DISCOUNT"></xmas-banner>
    <cny-banner v-if="$store.getters.featureSwitchesState.MIDAUTUMN_SALE"></cny-banner>
    <header class="text_only">
      <h1>Translate your Family Tree Book</h1>
      <p>Unlock the stories, names and legacy of your family's zupu</p>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr label="Get a quote"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
    </header>

    <div class="readable_content intro">
      <img :data-src="$getAsset('/assets/video/mcr-reel.jpg')" class="lazyload with-shadow" width="800" height="450" />
      <p>
        Do you have a copy of your Chinese Family Tree Book (also known as a jiapu or zupu), but don't know what it
        contains or what it means?
      </p>
      <p>Are you curious about your ancestors, who they were, and what they accomplished?</p>
      <p>Our experts in China are here to help you make sense of it all!</p>
      <h4>Our Translation Packages</h4>
    </div>

    <div class="image_panel reverse_panel">
      <div class="image_panel_text">
        <h5>Table of Contents</h5>
        <div class="text-block">
          Are you simply interested to know what's in your zupu? We'll summarize the key sections in English, and
          provide a page index for your reference.
        </div>
        <router-link rel="nofollow" :to="{name: 'zupu-table-of-contents', query: routeQuery}">Learn More</router-link>
      </div>
      <div class="image_panel_image">
        <img
          :data-src="this.$getAsset('/assets/services/translation/screen_zupu.jpg')"
          class="lazyload"
          width="600"
          height="435"
        />
      </div>
    </div>
    <div class="image_panel">
      <div class="image_panel_text">
        <h5>Locate a Name</h5>
        <div class="text-block">
          Are your looking for someone specific in the zupu, but can't read Chinese? We'll locate it for you.
        </div>
        <router-link rel="nofollow" :to="{name: 'zupu-ancestor-name', query: routeQuery}">Learn More</router-link>
      </div>
      <div class="image_panel_image">
        <img
          :data-src="this.$getAsset('/assets/services/translation/zupu-page-highlighted-name.png')"
          class="lazyload"
          width="600"
          height="510"
        />
      </div>
    </div>
    <div class="image_panel reverse_panel">
      <div class="image_panel_text">
        <h5>Translate Your Full Lineage</h5>
        <div class="text-block">
          <div>Do you want to know all your ancestors, going as far back as possible?</div>
          <div>We'll identify them, and preserve them into an elegant ancestral line.</div>
        </div>
        <router-link rel="nofollow" :to="{name: 'zupu-ancestral-lineage', query: routeQuery}">Learn More</router-link>
      </div>
      <div class="image_panel_image">
        <img
          :data-src="this.$getAsset('/assets/services/tree/lineage-noshadow.jpg')"
          class="lazyload in-layer"
          width="600"
          height="490"
        />
      </div>
    </div>
    <div class="image_panel">
      <div class="image_panel_text">
        <h5>Find your Family Poem</h5>
        <div class="text-block">
          Are you looking for a way to name your kids or grandkids according to tradition? We’ll locate your generation
          poem.
        </div>
        <router-link rel="nofollow" :to="{name: 'zupu-generation-poem', query: routeQuery}">Learn More</router-link>
      </div>
      <div class="image_panel_image">
        <img
          :data-src="this.$getAsset('/assets/services/translation/generation-poem-sample.png')"
          class="lazyload"
          width="600"
          height="370"
        />
      </div>
    </div>
    <div class="image_panel reverse_panel">
      <div class="image_panel_text">
        <h5>Transcribe your Full Tree</h5>
        <div class="text-block">
          We’ll translate and convert your tree into a digital version, perfect for sharing with the whole family.
        </div>
        <router-link rel="nofollow" :to="{name: 'zupu-transcribe', query: routeQuery}">Learn More</router-link>
      </div>
      <div class="image_panel_image">
        <img
          :data-src="this.$getAsset('/assets/services/family-tree-software-cropped.png')"
          class="lazyload in-layer"
          width="600"
          height="370"
        />
      </div>
    </div>

    <div class="readable_content">
      <hr />
      <h5 class="centered">
        Not what you had in mind? <br />
        Tell us your translation needs.
      </h5>
      <mcr-buttons-row>
        <mcr-button-router-link-to-pr label="Get in touch"></mcr-button-router-link-to-pr>
      </mcr-buttons-row>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

import cnyBanner from '@/components/common/promotions/cnyBanner';
import xmasBanner from '@/components/common/promotions/xmasBanner';

export default {
  metaInfo: {
    title: 'Translate Family Tree Book',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          "Do you have a Chinese family tree book (zupu or jiapu), but can't make sense of it? Work with Chinese genealogy specialist to translate, transcribe and make sense of your family's history.",
      },
    ],
  },
  computed: {
    routeQuery() {
      return {from: this.$route.query.from};
    },
  },
  components: {
    McrButtonRouterLinkToPr,
    mcrButtonsRow,
    xmasBanner,
    cnyBanner,
  },
};
</script>

<style scoped lang="scss">
@import '~@/components/common/styles/services.scss';
.image_panel {
  align-items: center;
}
.intro {
  padding-bottom: 0;
}
</style>
